<template>
  <div :id="id" :style="{width, height}"></div>
</template>

<script>
import barChart from './bar';

export default {
  name: 'BarChart',
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100%',
    },
    title: {
      type: String,
      default: '',
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
      deep: true,
    },
    yData: {
      type: Array,
      default() {
        return [];
      },
      deep: true,
    },
    xRotate: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    xData(val) {
      if (val) {
        this.setChart();
      }
    },
    yData(val) {
      if (val) {
        this.setChart();
      }
    },
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    setChart() {
      const self = this;
      barChart(this.id || 'chart-main', {
        title: this.title,
        xData: this.xData,
        orient:  this.config.orient,
        colorData: this.config.colorData,
        legend: {
          // show: this.config.legend.show,
        },
        toolbox: {
          show: false,
        },
        tooltipFormatter(params) {
          let result = '';
          for (let i = 0, length = params.length; i < length; i++) {
            const itemColor = params[i].color;
            result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${itemColor}"></span>`;
            result += `${params[i].name} ${params[i].seriesName}: ${params[i].value} ${self.config.unit}<br />`;
          }
          return result;
        },
        yAxis: {
          title: this.config.unit,
        },
        xAxis: {
          rotate: this.xRotate,
        },
        yData: this.yData,
      });
    },
  },
  created() {
  },
  mounted() {
    this.setChart();
  },
};
</script>

<style scoped>

</style>
