<template>
  <query-frame
    :add-visible="false"
    :page-visible="false"
    @on-query="handleQuery"
    @on-reset="handleReset"
  >
    <!-- 查询条件开始 -->
    <template slot="query">
      <query-item label="门店名称" v-if="isStoreUser">
        <store-list-select v-model="query.shopId"></store-list-select>
      </query-item>
      <query-item label="查询时间">
        <i-date-picker :allowClear="false" v-model="query.duration"></i-date-picker>
      </query-item>
    </template>
    <!-- 查询条件结束 -->
    <div slot="table">
      <a-row :gutter="16">
        <a-col>
          <a-row :gutter="16" class="statistic-report-card">
            <a-col :span="8">
              <a-card title="营业概览" style="height: 288px;">
                <h3 class="text-center">
                  <a-statistic
                    :value="infoData.salesStatis.toatalPrice"
                    :precision="2"
                    suffix="元"
                  ></a-statistic>
                  <div class="pb-10">营收总计</div>
                </h3>
                <a-descriptions :column="2" style="border-bottom: 1px solid #ddd">
                  <a-descriptions-item label="现金收款">
                    {{infoData.salesStatis.cashPrice}}元
                  </a-descriptions-item>
                  <a-descriptions-item label="码盒收款">
                    {{infoData.salesStatis.hspayPrice}}元
                  </a-descriptions-item>
                  <a-descriptions-item label="小程序收款">
                    {{infoData.salesStatis.hsMiniappPay}}元
                  </a-descriptions-item>
                  <a-descriptions-item label="会员消费">
                    {{infoData.salesStatis.memberPrice}}元
                  </a-descriptions-item>
                  <a-descriptions-item label="退单金额">
                    {{infoData.salesStatis.refundPrice}}元
                  </a-descriptions-item>
                </a-descriptions>
                <p class="pt-10">充值金额： {{infoData.salesStatis.rechargePrice}}元</p>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card title="订单概览" style="height: 288px;">
                <h3 class="pb-10">
                  订单总量<span style="display: inline-block; margin-left: 30px;">{{infoData.salesOrderStatis.orderNum}}</span>笔
                </h3>
                <a-descriptions :column="1">
                  <a-descriptions-item label="消费订单">
                    {{infoData.salesOrderStatis.customerNum}}笔
                  </a-descriptions-item>
                  <a-descriptions-item label="次卡订单">
                    {{infoData.salesOrderStatis.timesCardNum}}笔
                  </a-descriptions-item>
                  <a-descriptions-item label="消费退单">
                    {{infoData.salesOrderStatis.refundNum}}笔
                  </a-descriptions-item>
                  <a-descriptions-item label="退单金额">
                    {{infoData.salesOrderStatis.refundPrice}}元
                  </a-descriptions-item>
                  <a-descriptions-item label="会员卡退款">
                    {{infoData.salesOrderStatis.accountRefundPrice}}元
                  </a-descriptions-item>
                </a-descriptions>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card title="会员及客流" style="height: 288px;">
                <a-descriptions :column="1">
                  <a-descriptions-item label="客流统计">
                    {{infoData.memberStatis.number}}人
                  </a-descriptions-item>
                  <a-descriptions-item label="新增会员">
                    {{infoData.memberStatis.newMemberNum}}人
                  </a-descriptions-item>
                  <a-descriptions-item label="会员总数">
                    {{infoData.memberStatis.memberNum}}人
                  </a-descriptions-item>
                </a-descriptions>
              </a-card>
            </a-col>
          </a-row>
          <a-card class="mt-20" title="门店营收统计" v-if="isStoreUser">
            <a-table :rowKey="record => record.orderId" :columns="columns" :data-source="dataList" bordered :pagination="false"></a-table>
          </a-card>
          <a-card class="mt-20" :bordered="false" :body-style="{padding: '0'}">
            <div class="salesCard">
              <a-tabs default-active-key="1" size="large" :tab-bar-style="{marginBottom: '24px', paddingLeft: '16px'}">
                <a-tab-pane loading="true" tab="营业额" key="1">
                  <div class="amount-data">
                    <div class="amount-data-chart" :class="{'onecolumn': !isStoreUser}">
                      <ve-histogram width="100%" :legend-visible="false" :data="amountData"></ve-histogram>
                    </div>
                    <div class="amount-data-list" v-if="isStoreUser">
                      <rank-list title="门店销售排行榜" :list="dataList"/>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane tab="收款类型" key="2">
                  <a-row>
                    <a-col>
                      <ve-ring :data="payData"></ve-ring>
                    </a-col>
                  </a-row>
                </a-tab-pane>
                <a-tab-pane tab="订单量" key="3">
                  <a-row>
                    <a-col>
                      <ve-histogram :legend-visible="false" :data="countData"></ve-histogram>
                    </a-col>
                  </a-row>
                </a-tab-pane>
              </a-tabs>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </query-frame>
</template>

<script>
import queryMixin from '@/mixin/queryMixin';
import VeHistogram from 'v-charts/lib/histogram.common';
import VeRing from 'v-charts/lib/ring.common';

import {
  reportOrder,
  reportOrder7d,
  reportDayamt,
  reportDaycnt,
  reportInfo,
  reportPaymethod,
  reportShop
} from '@/api/finance';
import barChart from '../../components/charts/Bar.vue';
import RankList from '../../components/RankList.vue';
import IDatePicker from '../../components/IDatePicker.vue';
import moment from 'moment';

export default {
  name: 'todayRevenue',
  mixins: [queryMixin],
  components: {
    barChart,
    RankList,
    IDatePicker,
    VeHistogram,
    VeRing,
  },
  data() {
    const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    const endDate = moment(new Date()).format('YYYY-MM-DD');
    return {
      query: {
        shopId: '',
        duration: [startDate, endDate],
      },
      totalData: {},
      amountConfig: {
        unit: '元',
      },
      orderConfig: {
        colorData: ['#e77575', '#5bc0de', '#e79d47', '#f4a79d', '#f00', '#5cb85c'],
        orient: true,
        unit: '单',
      },
      popoverData: {
        memberStatis: {},
        salesOrderStatis: {},
        salesStatis: {},
      },
      amountData: {},
      infoData: {
        memberStatis: {},
        salesOrderStatis: {},
        salesStatis: {},
      },
      countData: {},
      payData: {},
      orderYData: [],
      columns: [
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
          width: 200
        },
        {
          title: '营收总额', // 会员显示名称 非会员显示散客
          dataIndex: 'toatalPrice',
          key: 'toatalPrice',
          width: 200,
          ellipsis: true,
        },
        {
          title: '订单总量',
          dataIndex: 'orderNum',
          key: 'orderNum',
          ellipsis: true,
          width: 200
        },
        {
          title: '退单总量',
          dataIndex: 'refundNum',
          key: 'refundNum',
          ellipsis: true,
          width: 200
        },
        {
          title: '客流人次',
          dataIndex: 'number',
          key: 'number',
          ellipsis: true,
          width: 200
        },
        {
          title: '新增会员',
          dataIndex: 'newMemberNum',
          key: 'newMemberNum',
          ellipsis: true,
          width: 200,
        },
        {
          title: '会员总数',
          dataIndex: 'memberNum',
          key: 'memberNum',
          width: 200,
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-popover trigger="click" placement="left">
                  <div slot="title">{row.shopName}</div>
                  <template slot="content">
                    <div class="pb-10">
                      <h3 class="pb-10">营收总额<span style="display: inline-block; margin-left: 30px;">{this.popoverData.salesStatis.toatalPrice}</span>元</h3>
                      <ul class="popover-data">
                        <li class="popover-data-item">
                          现金收款<span class="popover-data-subitem">{this.popoverData.salesStatis.cashPrice}元</span>
                        </li>
                        <li class="popover-data-item">
                          聚合收款<span class="popover-data-subitem">{this.popoverData.salesStatis.hspayPrice}元</span>
                        </li>
                        <li class="popover-data-item">
                          会员消费<span class="popover-data-subitem">{this.popoverData.salesStatis.memberPrice}元</span>
                        </li>
                        <li class="popover-data-item">
                          退单金额<span class="popover-data-subitem">{this.popoverData.salesStatis.refundPrice}元</span>
                        </li>
                      </ul>
                    </div>
                    <h3 class="pb-10">
                      充值金额<span style="display: inline-block; margin-left: 30px;">{this.popoverData.salesStatis.rechargePrice}</span>元
                    </h3>
                    <h3 class="pb-10">
                      订单总量<span style="display: inline-block; margin-left: 30px;">{this.popoverData.salesOrderStatis.orderNum}</span>笔
                    </h3>
                    <ul class="popover-data">
                      <li class="popover-data-item">
                        消费订单<span class="popover-data-subitem">{this.popoverData.salesOrderStatis.customerNum}笔</span>
                      </li>
                      <li class="popover-data-item">
                        消费退单<span class="popover-data-subitem">{this.popoverData.salesOrderStatis.refundNum}笔</span>
                      </li>
                    </ul>
                    <h3 class="pb-10">
                      会员及客流
                    </h3>
                    <ul class="popover-data">
                      <li class="popover-data-item">
                        会员总数<span class="popover-data-subitem">{this.popoverData.memberStatis.memberNum}人</span>
                      </li>
                      <li class="popover-data-item">
                        新增会员<span class="popover-data-subitem">{this.popoverData.memberStatis.newMemberNum}人</span>
                      </li>
                      <li class="popover-data-item">
                        客流统计<span class="popover-data-subitem">{this.popoverData.memberStatis.number}人</span>
                      </li>
                    </ul>
                  </template>
                  <a-button type="link" size="small" onClick={ () => this.handleShowInfo(row) }>查看门店详情</a-button>
                </a-popover>
              </section>
            );
          }
        }
      ],
      dataList: [],
    };
  },
  computed: {
    isStoreUser() {
      const userInfo = this.$store.getters.getUserInfo;
      return userInfo.userDTO.shopId === 0;
    }
  },
  watch: {},
  created() {
    this.handleQuery();
    // this.getReportOrder7d();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取订单列表
    getData() {
      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }

      reportDayamt(this.params).then((data) => {
        const rows = [];
        data.amounts.forEach((item, index) => {
          rows.push({
            '日期': data.days[index],
            '营业额': item,
          })
        });
        this.amountData = {
          columns: ['日期', '营业额'],
          rows
        };
      });

      reportDaycnt(this.params).then((data) => {
        const rows = [];
        data.countList.forEach((item, index) => {
          rows.push({
            '日期': data.days[index],
            '订单量': item,
          })
        });
        this.countData = {
          columns: ['日期', '订单量'],
          rows
        };
      });

      reportInfo(this.params).then((data) => {
        this.infoData = data;
      });
      reportPaymethod(this.params).then((data) => {
        const { methods, methodCount } = data;
        const rows = [];
        methods.forEach((item, index) => {
          rows.push({
            '日期': item,
            '收款方式': methodCount[index]
          })
        })

        this.payData = {
          columns: ['日期', '收款方式'],
          rows
        };
      });
      reportShop(this.params).then((data) => {
        this.dataList = data;
      });
    },

    // 7日订单
    getReportOrder7d() {
      reportOrder().then((data) => {
        console.log(data);
      });
    },
    handleReset() {
      const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
      const endDate = moment(new Date()).format('YYYY-MM-DD');

      const duration = [startDate, endDate];
      this.query = {
        // duration
      };
      setTimeout(() => {
        this.query = {
          duration
        };
      })
      // this.$set(this.query, 'duration', duration)
      // this.query.duration = duration;
    },
    handleShowInfo(row) {
      const params = { ...this.params };
      params.shopId = row.shopId;
      reportInfo(params).then((data) => {
        this.popoverData = data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/modules/variables";
.amount-data {
  display: flex;
  flex-flow: row nowrap;
  .amount-data-chart {
    position: relative;
    display: flex;
    flex: 0 0 65%;
    padding: 0;
    margin: 0;

    &.onecolumn {
      flex: 1 1 100%;
    }
  }

  .amount-data-list {
    flex: 0 0 35%;
    width: 400px;
  }
}

.popover-data {
  padding-left: 30px;
  width: 400px;

  .popover-data-item {
    margin-bottom: 10px;
  }
  .popover-data-subitem {
    display: inline-block;
    padding-left: 30px;
  }
}

</style>
